import { useMutation } from 'hooks/sympl-mutation';
import { TRANSITION_CANDIDATE } from 'graphql/candidates/mutations';

import { CandidateStates } from 'types/candidates/types';

interface CandidateTransitionPayload {
  new_state: CandidateStates;
  sm_email?: {
    subject?: string;
    body?: string;
  };
}

interface TransitionCandidateMutationParams {
  procedureId: number;
  input: CandidateTransitionPayload;
}

const useTransitionCandidateMutation = () => {
  const [
    transitionCandidate,
    { error: transitionCandidateError, loading: transitioningCandidate },
  ] = useMutation<undefined, TransitionCandidateMutationParams>(
    TRANSITION_CANDIDATE
  );

  return {
    transitionCandidate,
    transitionCandidateError,
    transitioningCandidate,
  };
};

export default useTransitionCandidateMutation;
