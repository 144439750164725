import tw, { styled } from 'twin.macro';
import { useQuery } from 'hooks/sympl-query';
import { useMutation } from 'hooks/sympl-mutation';

import Badge from 'components/badge/Badge';
import Button from 'components/button/Button';
import CandidateSidebar from 'components/candidates/sidebar/CandidateSidebar';
import AppPage from 'components/page/app-page/AppPage';
import ColorPickerPalette, {
  PaletteColor,
} from 'components/pickers/color-picker-palette/ColorPickerPalette';
import { Subhead } from 'components/typography/Typography';
import { CandidateDetailTab } from 'context/CandidateContext';
import { UPDATE_CANDIDATE_COLOR } from 'graphql/candidates/mutations';
import { GET_CANDIDATE } from 'graphql/candidates/queries';
import useCandidateContext from 'hooks/context/candidate-context';
import useTransitionCandidateMutation from 'hooks/candidates/transitionCandidate';
import useNavigationContext from 'hooks/context/nav-context';
import useWindowDimensions from 'hooks/viewport';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Prohibit, Tag } from '@phosphor-icons/react';
import { useParams, Navigate } from 'react-router-dom';
import { Candidate, CandidateProcedure } from 'types/candidates/types';
import { Routes } from 'types/routeTypes';
import {
  getNextState,
  getStateForCandidateProcedure,
} from 'utils/candidateHelpers';
import CandidateCommunication from './CandidateCommunication';
import CandidateGeneralInfo from './CandidateGeneralInfo';
import CandidateInterview from './CandidateInterview';
import CandidateNotes from './CandidateNotes';
import { Modal } from 'components/page/app-page/Modal';
import SendMailForm from 'components/candidates/send-mail-form/SendMailForm';
import { FormProvider, useForm } from 'react-hook-form';
import { useToastNotifications } from 'hooks/notificationHooks';
import { ToastTypes } from 'types/notificationTypes';

interface PageParams extends Record<string, string | undefined> {
  procedure?: string;
}

interface UpdateProcedureColorPayload {
  proc_id: string;
  input: { color: string };
}

const CandidateDetail: React.FC = () => {
  const formMethods = useForm();
  const fetchedVacancy = useRef<number>();
  const { procedure } = useParams<PageParams>();
  const { addToast } = useToastNotifications();
  const { width: viewportWidth } = useWindowDimensions();
  const { activeVacancy, setActiveVacancy, setActiveCustomer } =
    useNavigationContext();
  const { activeDetailTab, setActiveDetailTab } = useCandidateContext();

  const mailSubjectId = 'candidate-end-subject';
  const mailBodyId = 'candidate-end-body';

  const { transitionCandidate, transitioningCandidate } =
    useTransitionCandidateMutation();

  const TAB_KEYS = [
    CandidateDetailTab.DETAILS,
    CandidateDetailTab.INTERVIEW,
    CandidateDetailTab.COMMUNICATION,
    CandidateDetailTab.NOTES,
  ];

  const [isModalShown, setIsModalShown] = useState(false);
  const [candidateData, _setCandidateData] = useState<Candidate>();
  const [isCandidateRefetch, setIsCandidateRefetch] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isEndProcedureActive, setIsEndProcedureActive] = useState(false);

  const [currentState, setCurrentState] = useState<
    CandidateProcedure | undefined
  >(undefined);

  const procedureId = useMemo(
    () => (procedure ? parseInt(procedure) : 0),
    [procedure]
  );

  const generalInfoVisibleOnSide = useMemo(
    () => viewportWidth >= 1280,
    [viewportWidth]
  );

  const mailCountBadge = !!candidateData?.communications?.length ? (
    <Badge
      label={candidateData?.communications.length.toString()}
      color="gray"
    />
  ) : null;

  const tabs: React.ReactNode[] = useMemo(() => {
    return [
      ...(!generalInfoVisibleOnSide ? [<Subhead>Details</Subhead>] : []),
      <Subhead>Interview</Subhead>,
      <div tw="flex gap-x-2 place-items-center">
        <Subhead>Communication</Subhead>
        {mailCountBadge}
      </div>,
      <Subhead>Notes</Subhead>,
    ];
  }, [candidateData?.communications, generalInfoVisibleOnSide]);

  const {
    loading,
    data,
    error,
    refetch: _refetchCandidate,
  } = useQuery<
    {
      candidate: Candidate;
    },
    { procedureId?: number }
  >(GET_CANDIDATE, {
    skip: fetchedVacancy.current === activeVacancy,
    notifyOnNetworkStatusChange: !isCandidateRefetch,
    variables: { procedureId },
    fetchPolicy: 'network-only',
  });

  const [setProcedureColor] = useMutation<
    undefined,
    UpdateProcedureColorPayload
  >(UPDATE_CANDIDATE_COLOR);

  const currentKeys = generalInfoVisibleOnSide ? TAB_KEYS.slice(1) : TAB_KEYS;

  const activeTabIndex = useMemo(() => {
    return currentKeys.findIndex((t) => t === activeDetailTab);
  }, [activeDetailTab, currentKeys]);

  const nextCandidateState = useMemo(
    () =>
      currentState && candidateData ? getNextState(currentState) : 'applied',
    [candidateData, currentState]
  );

  const tabChangeHandler = (tabIndex: number) => {
    setActiveDetailTab?.(currentKeys[tabIndex]);
  };

  const modalCancelHandler = () => {
    setIsEndProcedureActive(false);
    setIsModalShown?.(false);
  };

  const setCandidateData = useCallback(
    (candidate: Candidate, mode: 'normal' | 'skip-mapping' = 'normal') => {
      if (mode !== 'skip-mapping') {
        // Map data coming from API
        _setCandidateData({
          ...candidate,
          state: getStateForCandidateProcedure(
            candidate.state as CandidateProcedure
          ),
          procedureActivities: Object.values(
            candidate.procedureActivities ?? []
          ),
        });
      } else {
        // Just set the data without mapping
        _setCandidateData({
          ...candidate,
        });
      }

      setCurrentState(candidate.state as CandidateProcedure);
    },
    []
  );

  const stopCandidateHandler = useCallback(() => {
    setIsEndProcedureActive(true);
    setIsModalShown?.(true);
  }, []);

  const refetchCandidate = useCallback(
    async (callback?: () => void) => {
      try {
        const { data } = await _refetchCandidate();
        if (data?.candidate) {
          setCandidateData(data.candidate);
        }
      } catch (_) {}

      callback?.();
    },
    [_refetchCandidate, setCandidateData]
  );

  const transitionCandidateHandler = useCallback(async () => {
    const newState = nextCandidateState;

    if (!newState) return;

    // Start the transition without comms
    await transitionCandidate({
      variables: {
        procedureId: candidateData?.procedureId ?? 0,
        input: {
          new_state: newState,
        },
      },
    });

    await refetchCandidate();
  }, [
    candidateData?.procedureId,
    nextCandidateState,
    refetchCandidate,
    transitionCandidate,
  ]);

  const modalTransitionHandler = (mode: 'start' | 'finish') => {
    if (mode === 'finish') {
      // Fetch the candidate details when the transition is finished
      refetchCandidate()?.then(() => setIsEndProcedureActive(false));
    }
  };

  const setProcedureHandler = useCallback(
    async (color: string) => {
      try {
        // Persist the changed color
        await setProcedureColor({
          variables: {
            proc_id: procedure ?? '0',
            input: {
              color,
            },
          },
        });

        // Refetch in order to show the updated data in the overview
        setIsCandidateRefetch(true);
        await refetchCandidate();

        // Update the color manually
        if (!candidateData) return;
        setCandidateData({ ...candidateData, color }, 'skip-mapping');

        // Reset the refetch flow to re-enable the loading state
        setIsCandidateRefetch(false);
      } catch (_) {}
    },
    [
      candidateData,
      procedure,
      refetchCandidate,
      setCandidateData,
      setProcedureColor,
    ]
  );

  const cta = useMemo(() => {
    return (
      <div tw="items-center mt-5 w-full hidden lg:flex sm:(mt-0 w-full justify-start)">
        <div tw="flex flex-row items-center">
          <Tag mirrored weight="fill" tw="text-gray-400" />
          <ColorPickerPalette
            defaultSelectedColor={
              (candidateData?.color as PaletteColor) ?? '#FFFFFF'
            }
            onColorSelected={(color) => setProcedureHandler(color)}
          />
        </div>
        {currentState !== 'end_procedure' && (
          <div tw="w-1/5 order-2 m-2 sm:(order-1 grow w-auto)">
            <Button variant="secondary" onClick={stopCandidateHandler} stretch>
              <p tw="flex justify-center w-full text-center">
                <span tw="hidden invisible sm:(block visible)">
                  {'End procedure'}
                </span>
                <Prohibit weight="bold" tw="sm:(hidden invisible)" size={20} />
              </p>
            </Button>
          </div>
        )}
        <div tw="w-4/5 order-1 my-2 sm:(order-2 w-auto mr-0) ">
          <Button
            loading={transitioningCandidate}
            onClick={transitionCandidateHandler}
            stretch
          >
            <div tw="inline-flex w-auto justify-center">
              Move to
              <p tw="ml-1">{nextCandidateState.replaceAll('_', ' ')}</p>
            </div>
          </Button>
        </div>
      </div>
    );
  }, [
    candidateData?.color,
    nextCandidateState,
    stopCandidateHandler,
    currentState,
    transitioningCandidate,
    transitionCandidateHandler,
    setProcedureHandler,
  ]);

  const skeleton = (
    <ul tw="flex flex-col">
      {[...Array(7)].map((_x, i) => (
        <li key={i} tw="flex flex-col h-full w-full mb-8">
          <div tw="bg-gray-200 h-6 w-full animate-pulse rounded-md" />
          <div tw="mt-2 bg-gray-200 h-4 w-3/5 animate-pulse rounded-md" />
        </li>
      ))}
    </ul>
  );

  useEffect(() => {
    if (data?.candidate) {
      // Sets active vacancy to vacancy of current procedure
      const { customerId, vacancyId } = data.candidate;

      // check if user hasnt toggled the campaign dropdown
      if (customerId && vacancyId) {
        setActiveVacancy({ vacancyId, customerId });
        // Set the currently fetched vacancy to prevent unnecessary requests
        fetchedVacancy.current = vacancyId;
      }

      setCurrentState(data.candidate.state as CandidateProcedure);

      setCandidateData(data.candidate);
    }
  }, [setCandidateData, data?.candidate, setActiveVacancy, setActiveCustomer]);

  useEffect(() => {
    if (!procedureId) return;
    refetchCandidate();
  }, [procedureId, refetchCandidate]);

  // If no procedure given, return to candidate overview
  if (!procedureId || (!loading && error))
    return <Navigate to={Routes.CANDIDATES} />;

  const submitTransitionFormHandler = async (skipEmail = false) => {
    const formData = formMethods.getValues();
    const email = {
      subject: formData[mailSubjectId],
      body: formData[mailBodyId],
    };
    const newState = isEndProcedureActive
      ? 'end_procedure'
      : nextCandidateState;

    modalTransitionHandler('start');

    await transitionCandidate({
      variables: {
        procedureId: procedureId,
        input:
          email && !skipEmail
            ? {
                new_state: newState,
                sm_email: email,
              }
            : {
                new_state: newState,
              },
      },
    })
      .then(() => {
        addToast({
          title: 'Candidate successfully moved.',
          description: 'The candidate has been successfully moved',
          type: ToastTypes.SUCCESS,
        });
        setIsModalShown(false);
        refetchCandidate();
      })
      .catch(() => {
        addToast({
          title: 'Something went wrong',
          description: 'Please try again. Contact us if this problem persists!',
          type: ToastTypes.ERROR,
        });
      });

    modalTransitionHandler('finish');
  };

  return (
    <AppPage
      heading={candidateData?.name ?? 'Candidate details'}
      loading={loading && !isCandidateRefetch}
      tabs={tabs}
      activeTabIndex={activeTabIndex}
      isTabsDisabled={isSaving}
      topPaddingOnly={true}
      onTabChange={(tabIndex) => tabChangeHandler(tabIndex)}
      breadcrumbUrl={Routes.CANDIDATES}
      cta={cta}
      skeleton={skeleton}
    >
      <FormProvider {...formMethods}>
        <div tw="flex w-full h-full grow-0 shrink-0">
          <div tw="flex flex-col w-full h-full pt-4 leading-7 overflow-hidden">
            {activeDetailTab === CandidateDetailTab.DETAILS &&
              candidateData &&
              !generalInfoVisibleOnSide && (
                <CandidateGeneralInfo
                  candidate={candidateData}
                  onChange={refetchCandidate}
                />
              )}
            {activeDetailTab === CandidateDetailTab.INTERVIEW && (
              <CandidateInterview survey={candidateData?.candidateSurvey} />
            )}
            {activeDetailTab === CandidateDetailTab.COMMUNICATION && (
              <CandidateCommunication
                newState={nextCandidateState}
                candidate={candidateData as Candidate}
                communications={candidateData?.communications}
                onSendMail={refetchCandidate}
              />
            )}
            {activeDetailTab === CandidateDetailTab.NOTES && (
              <CandidateNotes
                procedureId={procedureId}
                defaultValue={candidateData?.notes}
                onNotesSaved={refetchCandidate}
                isSaving={isSaving}
                setIsSaving={setIsSaving}
              />
            )}
          </div>
          {activeDetailTab !== CandidateDetailTab.DETAILS && (
            <CandidateSidebar
              candidate={candidateData as Candidate}
              onRefetchCandidate={refetchCandidate}
            />
          )}
        </div>

        <Modal show={isModalShown} onClose={modalCancelHandler}>
          <SendMailFormWrapper>
            <SendMailForm
              variant="normal"
              procedureId={procedureId}
              subjectId={mailSubjectId}
              bodyId={mailBodyId}
              onSend={() => submitTransitionFormHandler(false)}
              onSendLoading={transitioningCandidate}
              skippable={true}
              onSkip={() => submitTransitionFormHandler(true)}
              candidate={candidateData}
            />
          </SendMailFormWrapper>
        </Modal>
      </FormProvider>
    </AppPage>
  );
};

const SendMailFormWrapper = styled.div(tw`mt-2 max-w-4xl md:min-w-[56rem]`);

export default CandidateDetail;
